<template>
  <router-view />
</template>

<script>
// @ is an alias to /src
// import NavBar from "@/components/NavBar.vue";

export default {
  name: "HomeView",
  components: {},
};
</script>

<style lang="scss">
@import "styles/custom.scss";

@import "bootstrap/scss/bootstrap.scss";
// @import '~bootstrap-vue/src/index.scss';

body {
  background-color: transparent;
}
html {
  font-weight: 200;
  // background-color: #39f;
  /* background-image: linear-gradient(to top right, purple, violet, orange); */
  background: linear-gradient(#1e1d33, #427481, #1e1d33);
  background-size: 600% 600%;
  -webkit-animation: gradanimate 0.5s ease infinite;
  animation: gradanimate 10s ease infinite;
}
@-webkit-keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
@keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
</style>
