import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ClawView from "../views/ClawView.vue";
import UniqueView from "../views/UniqueView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/clawscreen",
    name: "clawscreen",
    component: ClawView,
  },
  {
    path: "/uniquestring",
    name: "uniquestring",
    component: UniqueView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
