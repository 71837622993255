<template lang="html">
  <img class="mt-3 mb-3" src="@/assets/logo.png" alt="logo" width="200" />
  <h2 class="title text-white">
    Ik ga akkoord met het verwerken van mijn persoonlijke gegevens zoals
    beschreven in het
    <a href="https://www.cedeo.nl/Page/PrivacyPolicy" target="_blank"
      >privacybeleid</a
    >
  </h2>
  <div class="d-grid gap-2">
    <button
      type="button"
      class="btn btn-primary text-light btn-lg rounded-pill"
      @click="saveQuestionGdpr()"
    >
      <span>Akkoord</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "QuestionGdprScreen",
  data: () => ({}),
  computed: {},
  methods: {
    addRemoveAnswer(answer) {
      if (this.checkedAnswers.indexOf(answer) !== -1) {
        this.checkedAnswers.splice(this.checkedAnswers.indexOf(answer), 1);
      } else {
        this.checkedAnswers.push(answer);
      }
    },
    answerChecked(answer) {
      if (this.checkedAnswers.indexOf(answer) !== -1) {
        return true;
      }
      return false;
    },
    saveQuestionGdpr() {
      this.$store.commit("setQuestionGdpr", "true");
      let data = {};
      data.vendor = process.env.VUE_APP_VENDOR;
      data.first_name = this.$store.getters.getFirstName;
      data.last_name = this.$store.getters.getLastName;
      data.phone = this.$store.getters.getPhone;
      data.company = this.$store.getters.getCompany;
      data.jobposition = this.$store.getters.getJobposition;
      data.email = this.$store.getters.getEmail;
      data.question_1 = this.$store.getters.getQuestionOne;
      data.question_2 = this.$store.getters.getQuestionTwo;
      data.question_3 = this.$store.getters.getQuestionThree;
      data.question_4 = this.$store.getters.getQuestionFour;
      data.scanned = false;
      this.$store.dispatch("saveVisitor", data);
    },
  },
};
</script>

<style lang="css" scoped>
h1 {
  font-size: 40px;
  color: #000;
}
.bg-field {
  color: #fdf7ea !important;
}
</style>
